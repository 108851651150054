.GuideSlider {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  gap: 40px;
  padding: 10px;
  padding-bottom: 20px;
}
.GuideItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  span {
    display: block;
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    &:hover {
      background-color: #f33f3a;
      color: #fff;
      border-color: #f33f3a;
    }
  }
}
.GuideImageContainer {
  height: 130px;
  width: 130px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
