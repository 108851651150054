.ProductCard {
  position: relative;
  line-height: 1.4em;
  max-width: 204px;
  flex-shrink: 0;
  border-radius: 10px;

  h3 {
    white-space: pre-wrap;
  }

  @media only screen and (max-width: 839px) {
    width: 44%;
  }
}

.ProductCard a {
  color: inherit;
  text-decoration: none;
}

.ProductCardImage {
  background-color: var(--box-background, #fff);
  border-radius: 4px;

  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 204px;
  height: 300px;

  @media screen and (max-width: 839px) {
    width: auto;
    height: 330px;
  }
  @media screen and (max-width: 640px) {
    width: auto;
    height: 270px;
  }
  @media screen and (max-width: 375px) {
    width: auto;
    height: 225px;
  }
  @media screen and (max-width: 320px) {
    width: auto;
    height: 205px;
  }
}

.ProductCardImage:hover img {
  transition: transform 1.6s !important;
  transform: scale3d(1.025, 1.025, 1.025);
}

.ProductCardTag {
  color: #f33f3a;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #f33f3a;
  margin-right: 10px;
  border-radius: 20px;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media only screen and (max-width: 839px) {
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 40%;
    padding: 0px 5px;
  }
  @media only screen and (max-width: 375px) {
    font-size: 38%;
  }
}

.ProductCardName {
  color: var(--text-color, #000);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.428;
  margin-top: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media only screen and (max-width: 839px) {
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 10px;
  }
}

.ProductCardRating {
  color: #f3e53a;
  font-weight: 700;
}

.ProductCardReviewRating {
  color: var(--text-color-muted);
  font-size: 14.4px;
  @media only screen and (max-width: 839px) {
    display: none;
  }
}

.ProductCardRatingStars svg {
  vertical-align: middle;
}

.ProductCardRatingReviews {
  position: relative;
  top: 2px;
}
.CityTourCardDetails {
  padding-bottom: 2px;
  padding-top: 2px;
  p {
    font-size: 14px;
    color: #565c5c;
    margin-bottom: 10px;
    line-height: 1.5em;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.ProductCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  @media only screen and (max-width: 768px) {
    gap: 3px;
  }
}

.DurationPrice {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 839px) {
    gap: 5px;
    font-size: 14px;
    flex-direction: column;
  }
}
.DDContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  .ProductCardReviewRating {
    display: block;
  }

  @media only screen and (max-width: 640px) {
    font-size: 12px !important;
    .ProductCardReviewRating {
      font-size: 12px !important;
    }
  }
  @media only screen and (max-width: 375px) {
    font-size: 10px !important;
    .ProductCardReviewRating {
      font-size: 10px !important;
    }
  }
}
