.LogoItem {
  width: 120px;
  height: 100px;
  position: relative;
  img {
    object-fit: contain !important;
  }
  &:hover {
    opacity: 0.8;
  }
  @media screen and (max-width: 640px) {
    width: 60px;
    height: 50px;
  }
}
