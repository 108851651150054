.ProductCardPriceDiscount {
  background: #de2c24;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 200;
  line-height: 1;
  margin-right: 6px;
  padding: 2px 5px;
  position: relative;
  top: -2px;
}

.ProductCardPricePerson {
  color: var(--text-color-muted, #676767);
}

.ProductCardPriceWithoutDiscount {
  color: var(--text-color-muted, #999);
  font-size: 8px;
  text-decoration: line-through;
}

.ProductCardPrice-PriceToPay {
  color: #de2c24;
  font-weight: 500;
  line-height: 1;
}

.ProductCardPriceToPayNormal {
  color: var(--text-color, #000);
  line-height: 1;
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 640px) {
  .ProductCardPriceToPayNormal {
    font-size: 18px;
  }
}
