@import "./layout";

.ExploreAllBtn {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  a {
    padding: 10px 20px;
    color: #ec1e79;
    background: #fff;
    display: inline-block;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;

    border: 1px solid #ec1e79;
    &:hover {
      color: #fff;
      background: #ec1e79;
      svg {
        fill: #fff;
      }
    }
    @include mq-down(md) {
      display: block;
      padding: 15px 20px;
      text-align: center;
    }
  }
}
.programPartners img {
  height: 100px;
  display: inline-block;
  filter: grayscale(1);
}
.programInfluencers {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: 20px;
}
.programInfluencers a {
  display: inline-block;
  width: 31.55%;
  margin: 10px;
  &:hover {
    opacity: 0.9;
  }
}
.programInfluencers img {
  width: 100%;
}
@media screen and (max-width: 839px) {
  .programInfluencers a {
    width: 46.55%;
  }
}
@media screen and (max-width: 640px) {
  .programInfluencers {
    gap: 10px;
    margin: 0;
  }
  .programInfluencers a {
    margin: 0;
    height: 217px !important;
    img {
      object-fit: contain !important;
    }
  }
  @media screen and (max-width: 375px) {
    .programInfluencers a {
      height: 190px !important;
    }
  }
}

@media screen and (max-width: 640px) {
  .programInfluencers a {
    width: 100%;
  }
}

.HomeCityProducts {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  @include mq-down(md) {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    gap: 15px;
  }
  & > div {
    width: 49%;

    @include mq-down(ml) {
      width: 31%;
      padding: 0;
      border: 0;
    }
    @include mq-down(md) {
      width: 44%;
      flex-shrink: 0;
      h3 {
        white-space: pre-wrap;
      }
    }
  }
  & > div > a > div > div:first-child {
    @include mq-down(md) {
      height: auto;
      padding-bottom: 151.9047619048%;
    }
  }
}

.CountryProducts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 20px;

  & > div {
    @include mq-down(ml) {
      padding: 0;
      border: 0;
    }
    @include mq-down(md) {
      flex-shrink: 0;
      h3 {
        white-space: pre-wrap;
      }
    }
  }
  & > div > a > div > div:first-child {
    @include mq-down(ml) {
      height: auto;
      padding-bottom: 151.9047619048%;
    }
  }
}
