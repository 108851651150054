.PostersContainer {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .PostersContainer {
    white-space: nowrap;
    overflow-x: scroll;
  }
}
