.DropDown {
  position: relative;
  display: inline-block;
  width: 100%;
  .DropDownToggle {
    width: 100%;
    padding: 10px 12px;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: #f0f0f0;
      border-color: #ccc;
    }
  }
  .DropDownArrow {
    font-size: 0.8rem;
    color: #888;
    transition: transform 0.2s ease;
  }
  .DropDownMenu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    max-height: 220px;
    overflow-y: auto;
    z-index: 10;
    animation: fadeIn 0.3s ease;
  }
  .DropDownItem {
    padding: 10px 12px;
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
