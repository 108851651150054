.TodayTomorrowSearchContainer {
  padding: 20px;
  margin-top: 40px;
  h3 {
    margin-bottom: 20px;
    color: #1c1c1e;
    font-weight: 400;
    letter-spacing: 0.27px;
    line-height: 1.42857143;
    font-size: 22px;
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  @media screen and (min-width: 640px) {
    display: none;
  }
}
.DaySelection {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  div {
    padding: 10px;
    text-align: center;
    width: 33%;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    background-color: #e91e638a;
    line-height: 1.5em;
    cursor: pointer;
    &:hover {
      background-color: #e91e63;
    }
  }
}
