.TripAdvisorLabel {
  color: var(--text-form-selected, #000);
  background: var(--background-form-selected, "#000");
  border-radius: 3px;
  display: inline-block;
  font-size: 11px;
  left: 0;
  line-height: 1;
  padding: 6px 4px;
  text-align: center;
}

.TextLabel {
  background: #e02e21;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  color: var(--text-form-selected, #000);
  display: inline-block;
  font-size: 11px;
  left: 0;
  line-height: 1;
  padding: 6px 4px;
  text-align: center;
}

.TextLabelTripAdvisor {
  background: var(--background-form-selected, #000);
}
