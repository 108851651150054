.HomeSearchBar {
  width: 100%;
  max-width: 650px;
  margin-left: 3%;
  margin-top: 40px;
}

.BannerBottomShadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7.5rem;
  background: linear-gradient(
    180deg,
    transparent,
    rgba(14, 14, 14, 0.6),
    #141414
  );
}

.BannerContainer {
  position: relative;
  height: 55vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: #141414;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-height: 420px;
  overflow: hidden;
  margin: 0 auto;
}

.BannerContainerBackground {
  position: absolute;
  height: 55vh;
  max-height: 420px;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.BannerContainer h1 {
  color: #fff;
  text-shadow: 2px 2px 4px rgb(20 20 20 / 45%);
  width: 500px;
  font-weight: bold;
  line-height: 1.3em;
}
.BannerContainer h1 span {
  display: block;
}
.BannerContent {
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: flex-start;
  text-align: left;
  padding: 0;
  max-width: 1100px;
  margin: 0 auto;
}
.BannerContent p {
  font-size: 14px;
  max-width: 40vw;
  margin-top: 1vw;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgb(20 20 20 / 45%);
}
.BannerPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(20, 20, 20, 0.2);
}
.HomeBannerPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(30, 20, 90, 0.4);
}
.BannerVideoBtn {
  display: inline-flex;
  padding: 10px 30px;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  border: none;
  background-color: #e91e63;
  color: #f2f2f2;
  border-radius: 6px;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;
  margin-right: 20px;
}
.BannerVideoBtn:hover {
  opacity: 0.8;
}
.BannerBtnContainer {
  display: flex;
  margin-top: 10px;
}
.SoundBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .BannerContainer h1 {
    font-size: 26px;
    width: 100%;
  }
  .BannerContent p {
    font-size: 12px;
    max-width: 100%;
  }
  .BannerBtnContainer {
    position: absolute;
    bottom: 0;
    flex-direction: row;
    width: 100%;
    left: 0;
    justify-content: center;
  }

  .BannerVideoBtn {
    padding: 10px 20px;
    font-size: 11px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: auto;
  }
  .BannerBtnContainer a {
    margin-right: 0px;
  }
  .BannerVideoBtn > svg {
    width: 15px;
    height: 15px;
  }
  .SoundBtn {
    margin-top: -5px;
  }

  .BannerContainer {
    height: 290px;
  }
  .BannerContainerBackground {
    height: 290px;
  }
  .BannerContent {
    padding: 0 25px;
  }
}

.HomeBannerContainer {
  position: relative;
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: #141414;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-height: 640px;
  margin: 0 auto;
}
.VideoMssgContainer {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;
}

.HomeBannerContainerBackground {
  position: absolute;
  height: 80vh;
  max-height: 640px;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.HomeVideoMessage {
  width: 100%;
  position: relative;
  z-index: 100;
  max-width: 1200px;
  padding: 0 3%;
}

.MetaMessage h1 {
  color: #fff;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.16;
  margin-top: 0;
}

.MetaMessage h2 {
  font-size: 28px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .MetaMessage h1 {
    font-size: 32px;
  }

  .MetaMessage h2 {
    font-size: 20px;
  }
}

.TripAdvisorBadge {
  display: flex;
  position: absolute;
  top: 130%;
  z-index: 1;
  right: 5%;
  align-items: center;
  gap: 20px;
  color: #fff;
}
.TripAdvisorBadge .TripFameMsg {
  max-width: 230px;
  padding-left: 30px;
  border-left: 3px solid #fff;
  margin-left: 20px;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .TripAdvisorBadge {
    top: 120%;
  }
  .HomeBannerContainer {
    max-height: 540px;
  }
  .HomeBannerContainerBackground {
    max-height: 540px;
  }
}

@media screen and (max-width: 640px) {
  .TripAdvisorBadge {
    top: 130%;
    left: auto;
    right: auto;
    width: 100%;
    justify-content: center;
  }
  .TripAdvisorBadge > svg {
    width: 45px;
  }
  .TripAdvisorBadge .TripFameMsg {
    margin-left: 10px;
    font-size: 11px;
    border-left: 2px solid #fff;
    padding-left: 20px;
    max-width: 165px;
  }
  .HomeVideoMessage {
    padding: 0 5%;
  }
  .HomeBannerContainer,
  .HomeBannerContainerBackground {
    height: 50vh;
    max-height: 350px;
  }
  .HomeSearchBar {
    display: none;
  }
}
